import { useSnackbar, WithSnackbarProps, SnackbarProvider } from 'notistack';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface IProps {
	setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
	props.setUseSnackbarRef(useSnackbar());
	return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
	useSnackbarRef = useSnackbarRefProp;
};

const SnackbarUtilsConfigurator = () => {
	return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

const useStyles = makeStyles(theme => ({
	root: {
		'& > div': {
			fontSize: '1.4rem'
		},
		'& button > span': {
			color: theme.palette.secondary.main
		}
	},
	variants: {
		'& button > span': {
			color: '#fff'
		}
	}
}));

export function Snackbar({ children }) {
	const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });
	const classes = useStyles();

	const notistackRef = React.createRef();
	const onClickDismiss = key => () => {
		notistackRef.current.closeSnackbar(key);
	};

	const snackbarConfig = {
		classes: {
			root: classes.root,
			variantSuccess: classes.variants,
			variantError: classes.variants
		},
		maxSnack: 2,
		anchorOrigin: {
			vertical: up600 ? 'bottom' : 'top',
			horizontal: 'left'
		},
		action: key => <Button onClick={onClickDismiss(key)}>Dismiss</Button>,
		ref: notistackRef
	};

	return (
		<SnackbarProvider {...snackbarConfig}>
			<SnackbarUtilsConfigurator />
			{children}
		</SnackbarProvider>
	);
}

export default {
	success(msg: string, options: OptionsObject = {}) {
		this.toast(msg, { ...options, variant: 'success' });
	},
	warning(msg: string, options: OptionsObject = {}) {
		this.toast(msg, { ...options, variant: 'warning' });
	},
	info(msg: string, options: OptionsObject = {}) {
		this.toast(msg, { ...options, variant: 'info' });
	},
	error(msg: string, options: OptionsObject = {}) {
		this.toast(msg, { ...options, variant: 'error' });
	},
	toast(msg: string, options: OptionsObject = {}) {
		useSnackbarRef.enqueueSnackbar(msg, options);
	}
};

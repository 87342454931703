import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import useStyles from './styles';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from 'components/Login/Login';
// import Signup from 'components/Signup/Signup';
import { AdzilyLogo } from 'assets/svg-components';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';

function LoginPage() {
  const [formLogin] = useState(true);
  const { currentUser } = useSelector(state => state.auth);

  const transitionDuration = 400;
  const classes = useStyles();

  if (currentUser) {
    return <Redirect to="/" />;
  }
  const loginFormHeader = (
    <>
      <Typography variant="h4" gutterBottom>
        Sign in
      </Typography>
      {/* <Typography gutterBottom>
        New user?{' '}
        <Link className={classes.link} onClick={() => setFormLogin(true)}>
          Create an account
        </Link>
      </Typography> */}
    </>
  );
  // const signupFormHeader = (
  //   <>
  //     <Typography variant="h4" gutterBottom>
  //       Create an account
  //     </Typography>
  //     <Typography gutterBottom>
  //       Already have an account?{' '}
  //       <Link className={classes.link} onClick={() => setFormLogin(true)}>
  //         Sign in
  //       </Link>
  //     </Typography>
  //   </>
  // );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <AdzilyLogo className={classes.logo} />
        <div>
          <Collapse in={formLogin} mountOnEnter unmountOnExit timeout={transitionDuration}>
            <Login formHeader={loginFormHeader} />
          </Collapse>
          {/* <Collapse in={!formLogin} mountOnEnter unmountOnExit timeout={transitionDuration}>
            <Signup formHeader={signupFormHeader} />
          </Collapse> */}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

import { makeStyles } from '@material-ui/core/styles';
import backgroundUrl from 'assets/images/bg.jpg';

export default makeStyles(({ spacing, breakpoints }) => ({
  root: {
    backgroundImage: `url(${backgroundUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container: {
    padding: spacing(3, 4, 5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 850,
    margin: '0 auto',
    minHeight: '100vh',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  logo: {
    width: '1em',
    height: 'auto',
    color: '#fff',
    fontSize: '18rem',
    margin: '0 auto 40px',
    [breakpoints.up('md')]: {
      margin: '0 auto 0 0',
    },
  },
  link: {
    cursor: 'pointer',
    '&&': {
      textDecoration: 'underline',
    },
  },
}));

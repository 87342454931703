import { loginUser, setErrors } from 'redux/features/auth.slice';
import { put, takeLatest } from 'redux-saga/effects';
// import API from 'utils/api';
import snackbar from 'utils/snackbar';
// import loopObject from 'utils/helpers/loopObject';

// const login = fields => API.post('/login', fields);
// const register = fields => API.post('/signup', fields);
// const saveUserToLocalStorage = user => localStorage.setItem('user', JSON.stringify(user));
// const getUserFromLocalStorage = () => localStorage.getItem('user');

function* handleLoginUser(action) {
  try {
    // const { fields } = yield action.payload;
    yield put(setErrors({}));
    yield put(
      setErrors({ email: 'The email address that you’ve entered doesn’t match any account.' }),
    );
    // const resp = yield call(login, fields);
    // yield console.log(resp);
    // const user = yield resp.data;
    // yield call(saveUserToLocalStorage, user);
    // yield put(loginUserSuccess(user));
  } catch (error) {
    // const errors = yield call(loopObject, error.response.data, ([key, value]) => [key, value[0]]);
    // yield put(setErrors(errors));
    yield console.log(error.response);
    yield console.log(error.toString());
    yield snackbar.error('Unable to login.');
  }
}

export default function* watchAuth() {
  yield takeLatest(loginUser.toString(), handleLoginUser);
}

import { createAction, createReducer } from '@reduxjs/toolkit';

export const loginUserSuccess = createAction('auth/loginUserSuccess');
export const setErrors = createAction('auth/setErrors');
export const loginUser = createAction('auth/loginUser');
export const logoutUser = createAction('auth/logoutUser');
export const reAuthUser = createAction('auth/reAuthUser');
export const registerUser = createAction('auth/registerUser');

const initialState = {
  currentUser: JSON.parse(localStorage.getItem('user')) || null,
  errors: {},
};

export default createReducer(initialState, {
  [loginUserSuccess]: (state, action) => {
    const currentUser = action.payload;
    return {
      ...state,
      currentUser,
    };
  },
  [logoutUser]: (state, action) => {
    return {
      ...state,
      currentUser: null,
    };
  },
  [setErrors]: (state, action) => {
    const errors = action.payload;
    return {
      ...state,
      errors,
    };
  },
});
